import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import PageIntro from "../../components/PageIntro";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContactSection from "../../components/contactSection"
import ImageDisplay from "../../components/ImageDisplay";
import Highlight from "../../components/Highlight";
  
function ServicesDisplay({ data }) {
  
      const images = data.allFile.edges

      const marketing = [
        {
            category: "Branding",
            heading: "Shape your brand.",
            id: "4",
            text: "Inspire loyalty with a unique brand that resonates with your vision and customers.",
            extraText: "",
            keywords: [
                "Brand identity", 
                "Rebrands", 
                "Packaging", 
                "Logo design", 
                "Style guide"
            ],
            link: "/services",
            cta: "Design your vision",
        },
    ];
  
      return (
          <>
            <ImageDisplay 
                images={ images } 
                selection={["1"]}
              />
              {/* {
                marketing.map( service => 
                  <Highlight service={ service } images={ images } id={ service.id} />
                  )
              } */}

              <ImageDisplay 
                images={ images } 
                selection={["2"]}
              />
              <ImageDisplay 
                images={ images } 
                selection={["3"]}
                constrained
              />
              <ImageDisplay 
                images={ images } 
                selection={["4"]}
              />
          </>
      )
}



const ProductDetailsPage = ({ data }) => {
  
  return (
 
      <Layout navBarType="white">
          <Seo title="Services" />
          <PageIntro 
            title="The Peaks"
            headline="Adventure in style."
            />   
            <ServicesDisplay data={ data }/>
            <ContactSection />
      </Layout>
  )
}

export default ProductDetailsPage



export const query = graphql`
query ThePeaksPageQuery {
    allFile(
        filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "the-peaks"}}
        ) {
        edges {
            node {
            childImageSharp {
                gatsbyImageData
            }
            name
            }
        }
    }
}
`